<template>
  <footer id="footer">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-12 text-center">
          <ul class="footermenu">
            <li class="footerborder"><a href="../#about">About us</a></li>
            <li :class="is_beta ? 'footerborder' : 'footerborders'">
              <a href="../#services">Services</a>
            </li>
            <li class="footerborder" v-if="is_beta">
              <a :href="url_privacy">Privacy</a>
            </li>
            <li class="footerborders" v-if="is_beta">
              <a :href="url_tos">TOS</a>
            </li>
          </ul>
        </div>
        <div class="col-lg-6 col-md-12 socialfooter">
          <div class="social-links">
            <a href="mailto:desk@vezham.com" target="_blank">
              <ICON_Mail :size="icon_size" />
            </a>
            <a :href="whatsapp_api" target="_blank">
              <ICON_Whatsapp :size="icon_size" />
            </a>
            <a
              href="https://www.linkedin.com/company/vezham"
              target="blank"
              class="Linkedin"
            >
              <ICON_Linkedin :size="icon_size" />
            </a>
            <a
              href="https://twitter.com/vezham_vtpl"
              target="blank"
              class="twitter"
            >
              <ICON_Twitter :size="icon_size" />
            </a>
            <a
              href="https://www.facebook.com/Vezham-Technologies-pvt-ltd-111707021515672"
              target="blank"
              class="facebook"
            >
              <ICON_Facebook :size="icon_size" />
            </a>
            <a
              href="https://instagram.com/vezham_vtpl"
              target="blank"
              class="instagram"
            >
              <ICON_Instagram :size="icon_size" />
            </a>
            <!-- <a href="#" target="blank"> <ICON_telegram :size="icon_size"/></a> -->
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-2 col-md-1 text-center"></div>
        <div class="col-lg-8 col-md-12 text-center">
          <div class="copyright">
            Copyrights &copy; 2022, All Rights Reserved by Vezham Technologies
            Private Limited
          </div>
        </div>
        <div class="col-lg-2 col-md-1 text-center"></div>
      </div>
    </div>
  </footer>
  <!-- #footer -->
  <BackTop />
  <!--/ End Footer Area -->
  <!-- <a href="#" class="scroll-top">
    <i class="lni lni-chevron-up"></i>
  </a> -->
  <!-- <transition name="fade">
    <div id="pagetop" class="scroll-top" v-show="scY > 300" @click="toTop">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#ffffff"
        stroke-width="1"
        stroke-linecap="square"
        stroke-linejoin="arcs"
      >
        <path d="M18 15l-6-6-6 6" />
      </svg>
    </div>
  </transition> -->
</template>
<script>
import {
  whatsapp_api,
  url_privacy,
  url_tos,
  is_beta,
} from "@/constants/constant.js";
import ICON_Facebook from "@/assets/icons/Facebook.vue";
import ICON_Linkedin from "@/assets/icons/Linkedin.vue";
import ICON_Twitter from "@/assets/icons/Twitter.vue";
import ICON_Instagram from "@/assets/icons/Instagram.vue";
import ICON_Whatsapp from "@/assets/icons/Whatsapp.vue";
import ICON_Mail from "@/assets/icons/Mail.vue";
import { BackTop } from "ant-design-vue";
export default {
  components: {
    ICON_Facebook,
    ICON_Twitter,
    ICON_Instagram,
    ICON_Whatsapp,
    ICON_Linkedin,
    ICON_Mail,
    BackTop,
  },
  data() {
    return {
      is_beta,
      icon_size: 24,
      whatsapp_api,
      url_privacy,
      url_tos,
      scTimer: 0,
      scY: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll: function () {
      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 4000);
    },
    // toTop: function () {
    //   window.scrollTo({
    //     top: 0,
    //     behavior: "smooth",
    //   });
    // },
  },
};
</script>
<style scoped>
@media (max-width: 992px) {
  .socialfooter {
    text-align: center !important;
    padding: 10px 0px !important;
  }
}
.ant-back-top {
  right: 50px !important;
}

.socialfooter {
  text-align: right;
  padding: 0px 30px;
}
ul.social li {
  display: inline-block;
  margin: 8px;
}
ul.social li a {
  font-size: 16px;
  color: #eee;
  line-height: 20px;
  letter-spacing: 1px;
  list-style-type: none;
}
.social-links a {
  display: inline-block;
  color: #eee;
  padding: 8px;
  margin-right: 10px;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}
.social-links a:hover {
  color: #fff;
}
.social-links {
  padding-top: 15px;
}

@media (max-width: 991px) {
  .social-links {
    padding: 0px 20px 20px 20px;
  }
  /* .scroll-top {
    width: 50px !important;
    height: 50px !important;
    line-height: 50px !important;
    font-size: 30px !important;
    bottom: 115px !important;
  } */
}
/* One Click Scrool Top Button*/
/* .scroll-top {
  width: 45px;
  text-decoration: none;
  height: 45px;
  line-height: 45px;
  background: #0880e8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  color: #fff !important;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 98;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  border-radius: 5px;
}

.scroll-top:hover {
  -webkit-box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
  box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
  -webkit-transform: translate3d(0, -5px, 0);
  transform: translate3d(0, -5px, 0);
  background-color: #000000;
} */
/*======================================
	Footer CSS
========================================*/
/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/

#footer {
  background: #101522;
  padding: 0 0 25px 0;
  color: #eee;
  font-size: 14px;
}

#footer .footer-top {
  background: #040919;
  padding: 60px 0 30px 0;
}

#footer .footer-top .footer-info {
  margin-bottom: 30px;
}

#footer .footer-top .footer-info h3 {
  font-size: 26px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
  color: #fff;
}

#footer .footer-top {
  height: 40px;
  margin-bottom: 10px;
}

#footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  color: #fff;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 5px;
  color: #f82249;
  font-size: 18px;
}

#footer .footer-top .footer-links ul li {
  border-bottom: 1px solid #262c44;
  padding: 10px 0;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #eee;
}

#footer .footer-top .footer-links ul a:hover {
  color: #fff;
}

#footer .copyright {
  text-align: center;
  font-size: 12px;
}

#footer .credits {
  text-align: center;
  font-size: 13px;
  color: #ddd;
}
@media (max-width: 1199px) {
  #header .container {
    max-width: 100%;
  }

  .nav-menu a {
    padding: 8px 4px;
  }
  .footermenu > li {
    padding: 5px !important;
  }
}

@media (max-width: 991px) {
  #footer {
    padding: 40px 0 60px 0;
    font-size: 35px !important;
  }
  li.footerborder {
    font-size: 16px;
    text-decoration: none;
    list-style: none;
  }
  li.footerborders {
    font-size: 16px;
    text-decoration: none;
    list-style: none;
  }
  #footer .copyright {
    font-size: 16px !important;
    margin-bottom: 80px;
  }
  ul.social li a {
    font-size: 30px !important;
    line-height: 40px !important;
  }
  ul.social li {
    margin: 30px !important;
  }
  .social-links a {
    width: 35px !important;
    height: 35px !important;
    padding: 8px 6px !important;
    margin-right: 10px;
  }
  li.footerborder > a:hover {
    font-size: 16px !important;
  }
  ul.footermenu {
    display: block !important;
    text-align: center;
    padding: 0px;
  }
}
@media only screen and (min-width: 800px) {
  ul.footermenu {
    display: flex;
    padding: 30px 0px 0px 20px;
  }
  .footermenu > li {
    padding: 0 10px;
    margin-bottom: 10px;
    text-decoration: none;
    list-style: none;
  }
}
li.footerborder {
  border-right: 1px solid #ccc;
}
li.footerborder > a {
  color: #fff;
  text-decoration: none;
}
li.footerborders > a {
  color: #fff;
  text-decoration: none;
}
li.footerborder > a:hover {
  color: #fff;
}
li.footerborders > a:hover {
  color: #fff;
}
@media only screen and (max-width: 991px) and (min-width: 0px) {
  li.footerborder {
    border: none;
  }
}
</style>
